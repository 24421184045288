import * as yup from "yup";
// const phoneRules = "[+]{1}[0-9]{9,14}";
// const urlRules = /^(www\.|https?:\/\/)/;
// const urlRules = /^(https?:\/\/).*/;
const emailRules =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const letterRuls = /^[A-Za-z\s]+$/;
export const conferenceCompanySchema = yup.object().shape({
  companyName: yup.string().max(254, "Content is too large").required("Company Name is required"),
  country: yup.string().required("Country is required"),
  numDelegates: yup.string().required("Number of Delegates is required"),
  delegates: yup.array().of(
    yup.object({
      firstName: yup
        .string()
        .matches(letterRuls, "First Name can only include letters")
        .max(254, "Content is too large")
        .required("First Name is required"),
      lastName: yup
        .string()
        .matches(letterRuls, "Last Name can only include letters")
        .max(254, "Content is too large")
        .required("Last Name is required"),
      title: yup.string().max(254, "Content is too large").required("Job Title is required"),
      email: yup
        .string()
        .max(254, "Content is too large")
        .matches(emailRules, "Please enter a valid email")
        .required("Email is required"),
      shirtType: yup.string().required("Shirt Type is required"),
      shirtSize: yup.string().required("Shirt Size is required"),
    })
  ),
  spouses: yup.array().of(
    yup.object({
      firstName: yup
        .string()
        .matches(letterRuls, "First Name can only include letters")
        .max(254, "Content is too large")
        .required("First Name is required"),
      lastName: yup
        .string()
        .matches(letterRuls, "Last Name can only include letters")
        .max(254, "Content is too large")
        .required("Last Name is required"),
      email: yup
        .string()
        .max(254, "Content is too large")
        .matches(emailRules, "Please enter a valid email")
        .required("Email is required"),
    })
  ),
});

import * as yup from "yup";
const phoneRules = "[+]{1}[0-9]{9,14}";
// const urlRules = /^(www\.|https?:\/\/)/;
const urlRules = /^(https?:\/\/).*/;
const emailRules =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const onboardingSchema = yup.object().shape({
  companyName: yup.string().max(254, "Content is too large").required("Company Name is required"),
  unitNo: yup.string().max(254, "Content is too large").required("Street is required"),
  memberType: yup.string().nullable().trim().required("Member Type is required"),
  memberFee: yup
    .number()
    .typeError("Member Fee must be a number")
    .required("Member Fee is required"),
  country: yup.string().required("Country is required"),
  postcode: yup.string().max(254, "Content is too large").required("Postal code is required"),
  city: yup.string().max(254, "Content is too large").required("City is required"),
  bannerTitle: yup.string().max(499, "Content is too large").nullable().trim(),
  bannerBody: yup.string().max(7999, "Content is too large").nullable().trim(),
  marketsToDevelop: yup.array(),
  latitude: yup
    .number()
    .max(254, "Content is too large")
    .typeError("Latitude must be a number")
    .required("Latitude is required"),
  longitude: yup
    .number()
    .max(254, "Content is too large")
    .typeError("Longitude must be a number")
    .required("Longitude is required"),
  websiteUrl: yup
    .string()
    .max(254, "Content is too large")
    .matches(urlRules, "Enter correct url with 'https://'")
    .required("Website URL is required"),
  linkedInUrl: yup
    .string()
    .max(254, "Content is too large")
    .matches(urlRules, "Enter correct url with 'https://'"),
  email: yup
    .string()
    .max(254, "Content is too large")
    .matches(emailRules, "Please enter valid Email.")
    .required("Company Email is required"),
  officeContactNumber: yup
    .string()
    .max(254, "Content is too large")
    .matches(phoneRules, { message: "Please enter valid Phone number" })
    .required("Phone number is required"),
  approvedTimestamp: yup.string().required("Enrollment Date is required"),
  expiryDate: yup.string().required("Expiry Date is required"),
});

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

function NonIndexedPageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta
          name="description"
          content="THINK GLOBAL. CONNECT LOCAL - Embrace the power of connectivity and discover the power of collaboration with Freyt World."
        />
        <meta property="og:title" content="Freyt World" />
        <meta
          property="og:description"
          content="THINK GLOBAL. CONNECT LOCAL - Embrace the power of connectivity and discover the power of collaboration with Freyt World."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://portal.freytworld.com" />
        <meta
          property="og:image"
          content="https://portal.freytworld.com/static/media/fw-logo-ct-darkback.7fec2456c65af9fdc750.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="620" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:alt" content="Freyt World" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Freyt World" />
      </Helmet>
      <MDBox
        width="100%"
        height="100%"
        minHeight="100vh"
        bgColor={background}
        sx={{ overflowX: "hidden" }}
      >
        {children}
      </MDBox>
    </HelmetProvider>
  );
}

// Setting default values for the props for NonIndexedPageLayout
NonIndexedPageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the NonIndexedPageLayout
NonIndexedPageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default NonIndexedPageLayout;

import { GENERAL_CONSTS } from "utils/constants/general.constants";

// var API_BASE_URL = "http://localhost:9000/";
var BASE_URL = process.env.REACT_APP_BASE_URL;

var API_BASE_URL = BASE_URL + "user-service/users";
var CREATE_USER_BASE_URL = BASE_URL + "uaa/create-new-sysuser";
var GATEWAY_LOGIN_URL = BASE_URL + "uaa/login";
var GATEWAY_CHANGE_PWD_URL = BASE_URL + "uaa/change-pwd";
var GATEWAY_PWD_RESET_EMAIL_URL = BASE_URL + "uaa/reset-pwd/email";
var GATEWAY_PWD_RESET_URL = BASE_URL + "uaa/reset-pwd";
var FIREBASE_AUTH_BASE_URL = process.env.REACT_APP_FIREBASE_AUTH_BASE_URL;
var CUSTOM_TOKEN_BASE_URL = BASE_URL + "uaa/custom-token";
var CURRENT_USER_BASE_URL = BASE_URL + "user-service/users/current-user";
var USER_PROFILE_UPDATE_REQUEST_BASE_URL = BASE_URL + "user-service/users/update-request";
var UPLOAD_FILE_URL = BASE_URL + "content-repo-service/content-repo/upload-file";
var COPY_FILE_URL = BASE_URL + "content-repo-service/content-repo/copy-file";
var LIST_FILES_URL = BASE_URL + "content-repo-service/content-repo/view-files";
var LOGIN_BASE_URL = BASE_URL + "audit-service/audit/login";

const TENANT_ID = "FW";

export async function getCustomToken(userToken) {
  const response = await fetch(CUSTOM_TOKEN_BASE_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken,
    },
  });
  //console.log("Custom Token :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading Current User" });
  }
}

export async function getCurrentUser(token, isLogin) {
  const response = await fetch(CURRENT_USER_BASE_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      userId: isLogin,
    },
  });
  //console.log("Current User Details :", response);
  if (response.ok) {
    return response.json();
  } else {
    const errReponse = {
      status: response.status,
    };
    return errReponse;
  }
}

export async function firebaseAuth(userCredentials) {
  try {
    const response = await fetch(FIREBASE_AUTH_BASE_URL, {
      method: "PUT",

      headers: {
        // "auth-token": userToken,
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(userCredentials),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function addNewUser(userDetails, userToken) {
  //console.log("new user data in service : ", JSON.stringify(userDetails));
  try {
    const response = await fetch(CREATE_USER_BASE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(userDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.json();
    return responseTxt;
  }
}

export async function signInUser(email, password) {
  try {
    const response = await fetch(GATEWAY_LOGIN_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        email: email,
        password: password,
      }),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function sendPwdResetEmail(email) {
  try {
    const response = await fetch(GATEWAY_PWD_RESET_EMAIL_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        email: email,
      }),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function changePassword(userToken, email, password) {
  try {
    const response = await fetch(GATEWAY_CHANGE_PWD_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        email: email,
        password: password,
      }),

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function resetPassword(oobCode, password) {
  try {
    const response = await fetch(GATEWAY_PWD_RESET_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        oobCode: oobCode,
        newPassword: password,
      }),

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUserById(id, userToken) {
  const response = await fetch(API_BASE_URL + "/data/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function getUserByEmail(email, userToken) {
  const response = await fetch(API_BASE_URL + "/email/" + TENANT_ID + "/" + email, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function updateUserFavourites(userId, memberId, userToken) {
  try {
    const response = await fetch(API_BASE_URL + "/favourites/" + userId, {
      method: "PUT",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: memberId,
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateUserByID(id, userToken, updatedData) {
  //console.log("Updated data: ", JSON.stringify(updatedData));
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "PUT",

      headers: {
        Authorization: userToken,
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function deleteUserByID(id, userToken) {
  try {
    const response = await fetch(BASE_URL + "uaa/delete-sysuser/" + id, {
      method: "DELETE",

      headers: {
        Authorization: userToken,
        "Content-Type": "application/json; charset=UTF-8",
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

// export async function updateUserAddressByID(id, userToken, updatedAddressData) {
//   //console.log("Updated Address data: ", JSON.stringify(updatedAddressData));
//   try {
//     const response = await fetch(API_BASE_URL + "/" + id + "/address", {
//       method: "PUT",

//       headers: {
//         Authorization: userToken,
//         "Content-Type": "application/json; charset=UTF-8",
//       },
//       body: JSON.stringify(updatedAddressData),
//     });

//     if (!response.ok) {
//       throw response;
//     } else {
//       return "ok";
//     }
//   } catch (error) {
//     let responseTxt = await error.text();
//     return responseTxt;
//   }
// }

export async function getAllUsers(data, token, offset, limit) {
  try {
    const response = await fetch(BASE_URL + "user-service/users/search", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: { textSearch: data },
        offset: offset,
        limit: limit,
        sortBy: ["display_name"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUsersFromIdList(data, token) {
  try {
    const response = await fetch(API_BASE_URL + "/get-users", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function exportUsers(token) {
  try {
    const response = await fetch(API_BASE_URL + "/export", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

// function dataURLtoFile(dataurl, filename) {
//   return new Promise((resolve, reject) => {
//     var arr = dataurl.split(","),
//       mime = arr[0].match(/:(.*?);/)[1],
//       bstr = atob(arr[1]),
//       n = bstr.length,
//       u8arr = new Uint8Array(n);

//     while (n--) {
//       u8arr[n] = bstr.charCodeAt(n);
//     }
//     ////console.log([u8arr], filename, { type: mime });
//     return resolve(new File([u8arr], filename, { type: mime }));
//   }).catch((e) => {
//     return {};
//   });
// }

export async function copyFileToBucket(
  destinationFolder,
  destinationPath,
  sourceFolder,
  sourceFile,
  userToken
) {
  const formdata = new FormData();
  formdata.append("appId", "FreytWorld");
  formdata.append("destinationFolder", destinationFolder);
  formdata.append("destinationPath", destinationPath);
  formdata.append("sourceFolder", sourceFolder);
  formdata.append("sourceFile", sourceFile);
  try {
    const response = await fetch(COPY_FILE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Authorization: userToken,
        "x-amz-acl": "public-read",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.text();
      // return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function uploadDocument(
  file,
  folderName,
  userToken,
  fileName,
  description,
  thumbnail
) {
  //console.log("file in service : ", file);

  const formdata = new FormData();
  formdata.append("appId", "FreytWorld");
  formdata.append("file", file);
  if (fileName !== null) {
    formdata.append("thumbnail", thumbnail);
    formdata.append("fileName", fileName);
    formdata.append("description", description);
  }
  formdata.append("folder", GENERAL_CONSTS.DOCUMENT_UPLOAD_ROOT_FOLDER + folderName);
  try {
    const response = await fetch(UPLOAD_FILE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Authorization: userToken,
        "x-amz-acl": "public-read",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
      // return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAllDocumentsInFolder(folderName, userToken) {
  const formdata = new FormData();
  formdata.append("appId", "FreytWorld");
  formdata.append("folder", folderName);
  try {
    const response = await fetch(LIST_FILES_URL, {
      method: "POST",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Authorization: userToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUserLastLoginById(id, userToken) {
  const response = await fetch(LOGIN_BASE_URL + "/" + id + "/latest", {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function getUserLoginCountById(id, userToken) {
  const response = await fetch(LOGIN_BASE_URL + "/" + id + "/count", {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function createUserUpdateRequest(userToken, requestId, updatedData) {
  try {
    const response = await fetch(`${USER_PROFILE_UPDATE_REQUEST_BASE_URL}/${requestId}`, {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(updatedData),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error submitting update request" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUserUpdateRequests(userToken, offset) {
  try {
    const response = await fetch(USER_PROFILE_UPDATE_REQUEST_BASE_URL + "/search", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        offset: offset * 20,
        limit: 20,
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUserUpdateRequestById(userToken, requestId) {
  try {
    const response = await fetch(`${USER_PROFILE_UPDATE_REQUEST_BASE_URL}/${requestId}`, {
      method: "GET",
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error retrieving data" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateUserUpdateRequestStatus(userToken, requestId, status) {
  try {
    const response = await fetch(`${USER_PROFILE_UPDATE_REQUEST_BASE_URL}/${requestId}`, {
      method: "PUT",
      body: status,
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error updating data" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAllLoginRecords(data, userToken, offset) {
  try {
    const response = await fetch(LOGIN_BASE_URL + "/records", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: { textSearch: data },
        offset: offset * 20,
        limit: 20,
        sortBy: ["display_name"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: userToken,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function exportLoginRecords(token) {
  try {
    const response = await fetch(LOGIN_BASE_URL + "/export", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function sendNotificationEmail(content) {
  try {
    const response = await fetch(BASE_URL + "notification-service/notifications/email", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(content),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return "ok";
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

import { GENERAL_CONSTS } from "../utils/constants/general.constants";

var API_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
var token = process.env.REACT_APP_CMS_ANNOUNCEMENTS_TOKEN;
var conRegToken = process.env.REACT_APP_CMS_CON_REGISTER_TOKEN;
var BASE_URL = process.env.REACT_APP_BASE_URL;
var DELETE_FILE_URL = BASE_URL + "content-repo-service/content-repo/delete-file";
var CONFERENCE_SERVICE_URL = BASE_URL + "audit-service/conference";
var CONFERENCE_SERVICE_TOKEN =
  "eyJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImNsYWltcyI6eyJwaG9uZU51bWJlciI6IjAxMTIyNDIzNTQiLCJkaXNwbGF5TmFtZSI6IlRFU1QxMjMiLCJyb2xlcyI6WyJTVVBFUl9BRE1JTiIsIlNZU19VU0VSIl0sImV4cGlyeSI6MTY4MjM2NzE4MTc4NywiYXZhdGFyIjoic3RyaW5nIiwiZmNtVG9rZW4iOnt9LCJ0ZW5hbnQiOiJja2xkbnI5dGplcmZvZXJmIiwib3JnSWQiOiIifSwiZXhwIjoxNjgyMzQxOTgxLCJpYXQiOjE2ODIzMzgzODEsImlzcyI6ImZpcmViYXNlLWFkbWluc2RrLWZwaGlsQGZyZXl0LXdvcmxkLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWZwaGlsQGZyZXl0LXdvcmxkLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6ImJ6dDVReVpxZWdiZEluWHpIc0ppZ0I5YzIzbjEifQ.outeaM-D2DLuxwyqhk157t2a8q0R_BmGMArtsNNic9tJXBr9FtOckbwEIeyGDKPxMte1GzdNHdE2n0QMWlThvi3VoezLmZvAuHgWCdDuXRjRdOODT7orF1dsLe1JldYedurfPV-DwfzClzK_bPuu58YitVONcnHV8qohcAsGhog-BDjeNQIzPv4q1rDZinNzUjBF7t-2lBzxVZ2eD9x6kAhhaKTwNm0GKesV1G6XslYKTFVdKJwYvRB62mll7uLY56D-TeU7DQldJuMkahekXeyhmsvx2Dt166II7X0-jTcmSgcRkMWIVcQSXAyFh9w-vYqc5UkzKTzJGeLWxz72Qg";

export async function getAllAnnouncements(limit) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/announcements/not-expired${limit !== null ? `?limit=${limit}` : ""}`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function newAnnouncement(content) {
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(content),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateAnnouncementById(id, updatedData) {
  //console.log("Updated announcement data: ", JSON.stringify(updatedData));
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAnnouncementById(id) {
  const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: token,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function removeAnnouncement(id) {
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function registerConferenceDelegates(delegateInfo) {
  try {
    const response = await fetch(`${CONFERENCE_SERVICE_URL}/register`, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(delegateInfo),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "x-api-token": CONFERENCE_SERVICE_TOKEN,
      },
    });
    return response;
  } catch (error) {
    let responseTxt = await error.json();
    return responseTxt;
  }
}

export async function getConferenceSlotAvailability() {
  try {
    const response = await fetch(`${CONFERENCE_SERVICE_URL}/availability`, {
      method: "GET",

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "x-api-token": CONFERENCE_SERVICE_TOKEN,
      },
    });
    return response.json();
  } catch (error) {
    let responseTxt = await error.json();
    return responseTxt;
  }
}

export async function getAllConRegistrations(data, filter, page) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/conference-registrations?filters[company_name][$containsi]=${data}&populate=address&populate=attendees&pagination[page]=${page}&pagination[pageSize]=100`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: conRegToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getConRegistrationStatics(token) {
  try {
    const response = await fetch(`${CONFERENCE_SERVICE_URL}/statistics`, {
      method: "GET",
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function addConfAttendee(attendeeDetails) {
  //console.log("new member data in service : ", JSON.stringify(attendeeDetails));
  try {
    const response = await fetch(`${API_BASE_URL}/api/conference-registrations`, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(attendeeDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getConfRegId(id) {
  //console.log("id in serivice", id);
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/conference-registrations?filters[attendees][user_id][$contains]=${id}`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: conRegToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading id" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function removeConfRegAttendee(id) {
  //console.log("delete reg id in serivice", id);
  try {
    const response = await fetch(API_BASE_URL + "/api/conference-registrations/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: conRegToken,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function deleteDocument(userToken, folderName, fileName) {
  const formdata = new FormData();
  formdata.append("appId", "FreytWorld");
  formdata.append("fileName", fileName);
  formdata.append("folder", GENERAL_CONSTS.DOCUMENT_UPLOAD_ROOT_FOLDER + folderName);
  try {
    const response = await fetch(DELETE_FILE_URL, {
      method: "DELETE",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Authorization: userToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

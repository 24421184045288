// react-router-dom components
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/bg-sign-in-basic.webp";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import shirtSizingChart from "assets/images/conference-shirt-sizing-chart.png";
import { Card, FormControl, InputLabel, Select } from "@mui/material";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import MenuItem from "@mui/material/MenuItem";

import { useFormik } from "formik";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { conferenceCompanySchema } from "layouts/validationSchema/conferenceCompanySchema";
import OnlineFormLayout from "layouts/authentication/components/OnlineFormLayout/onlineFormLayout";
import { registerConferenceDelegates } from "api_services/OtherServices";
import { getConferenceSlotAvailability } from "api_services/OtherServices";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9BE06A",
      darker: "#9BE06A",
    },
  },
});

function ConferenceCompanyInfo() {
  // const [avatar, setAvatar] = useState("");
  const [saving, setSaving] = useState(false);
  const [hasBooth, setHasBooth] = useState(false);
  const [availability, setAvailability] = useState({});
  const [numDelegates, setNumDelegates] = useState("");
  const [sponsorshipList, setSponsorshipList] = useState([]);
  const [assignedBooth, setAssignedBooth] = useState(0);
  const [golfSlotsAvailable, setGolfSlotsAvailable] = useState(0);
  const navigate = useNavigate();

  const [positionState, setPositionState] = useState({
    openn: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openn } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, openn: false });
  };

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const setSlotAvailability = async () => {
      const response = await getConferenceSlotAvailability();
      setAvailability(response);
      setGolfSlotsAvailable(response["golfday"]);
      handleNumDelegateChange({ target: { value: 2 } });
    };
    setSlotAvailability();
  }, []);

  async function registerForConference(regInfo) {
    const { tenantId, companyName, country, sponsorship, booth, delegates, spouses } = regInfo.data;
    const companyInfo = {
      tenantId: tenantId,
      companyName: companyName,
      country: country,
      sponsorship: sponsorship,
      booth: booth === "true" ? true : false,
    };
    const delegateList = [];
    let slotList = {
      delegate_pass: delegates.length,
      spouse_pass: spouses.length,
      booth: booth === "true" ? 1 : 0,
      golfday: 0,
      platinum_sponsor: 0,
      gold_sponsor: 0,
      onetoonemeeting_sponsor: 0,
      coffeebreak_sponsor: 0,
      lanyard_sponsor: 0,
      golf_sponsor: 0,
      tablenumber_sponsor: 0,
      conferencedirectory_sponsor: 0,
      flask_sponsor: 0,
      pen_sponsor: 0,
      massage_sponsor: 0,
      teambuilding_sponsor: 0,
      stapler_sponsor: 0,
    };
    let golfSlots = 0;
    delegates.forEach((delegate) => {
      const delegateInfo = {
        type: "DELEGATE",
        ...companyInfo,
        ...delegate,
        assignToBooth: booth === "true" ? delegate.assignToBooth : false,
        oneToOneMeetingTogether: delegate.oneToOneMeetingTogether === "true" ? true : false,
      };
      if (delegate.golfDay) {
        ++golfSlots;
      }
      delegateList.push(delegateInfo);
    });
    spouses.forEach((spouse) => {
      const spouseInfo = {
        type: "SPOUSE",
        ...companyInfo,
        firstName: spouse.firstName,
        lastName: spouse.lastName,
        title: "N/A",
        email: spouse.email,
        shirtType: "N/A",
        shirtSize: "N/A",
        golfDay: spouse.golfDay,
        assignToBooth: false,
        oneToOneMeetingTogether: false,
      };
      if (spouse.golfDay) {
        ++golfSlots;
      }
      delegateList.push(spouseInfo);
    });
    sponsorshipList.forEach((sponsorKey) => {
      slotList = {
        ...slotList,
        [sponsorKey]: 1,
      };
    });
    slotList = {
      ...slotList,
      golfday: golfSlots,
    };
    // console.log("Registration Info: ", delegateList);
    // console.log("Slot Info: ", slotList);
    const conferenceRegistration = {
      delegates: delegateList,
      slots: slotList,
    };
    const response = await registerConferenceDelegates(conferenceRegistration);
    if (response.ok) {
      setOpen(false);
      setOpenTwo(true);
    } else {
      setOpen(false);
      setSaving(false);
      setPositionState({ openn: true, vertical: "top", horizontal: "center" });
    }
  }

  const submitData = async () => {
    setSaving(true);
    const confRegObj = {
      data: {
        tenantId: "Freyt World",
        companyName: values.companyName,
        country: values.country,
        sponsorship: values.sponsorship,
        booth: values.booth,
        delegates: values.delegates,
        spouses: values.spouses,
      },
    };

    registerForConference(confRegObj);
  };

  const clearLocal = () => {
    navigate("/authentication/sign-in");
  };

  const handleSponsorshipChange = (sponsorshipType, sponsorshipKey) => {
    const sponsorships = values && values.sponsorship.length > 0 ? values.sponsorship : [];
    const sponsorshipKeys = sponsorshipList;
    let updatedList = [];
    let updatedKeyList = [];
    if (sponsorships.includes(sponsorshipType)) {
      updatedList = sponsorships.filter((s) => s !== sponsorshipType);
      updatedKeyList = sponsorshipKeys.filter((s) => s !== sponsorshipKey);
    } else {
      sponsorships.push(sponsorshipType);
      updatedList = sponsorships;
      sponsorshipKeys.push(sponsorshipKey);
      updatedKeyList = sponsorshipKeys;
    }
    setSponsorshipList(updatedKeyList);
    setFieldValue("sponsorship", updatedList);
  };

  const assignDefaultDelegatesToBooth = (numDelegates) => {
    if (values?.delegates) {
      if (numDelegates == 1) {
        handleCheckChange(`delegates[0].assignToBooth`, true, false, false);
        setAssignedBooth(1);
      } else if (numDelegates == 2) {
        handleCheckChange(`delegates[1].assignToBooth`, true, false, false);
        handleCheckChange(`delegates[0].assignToBooth`, true, false, false);
        setAssignedBooth(2);
      }
    }
  };

  const handleCheckChange = (fieldName, value, assignBoothNum, golfDaySlot) => {
    setFieldValue(fieldName, value);
    if (fieldName === "booth") {
      setHasBooth(value === "true" ? true : false);
      if (value === "true") {
        assignDefaultDelegatesToBooth(values.numDelegates >= 2 ? 2 : 1);
      }
    } else if (assignBoothNum) {
      setAssignedBooth(value ? assignedBooth + 1 : assignedBooth - 1);
    } else if (golfDaySlot) {
      setGolfSlotsAvailable(value ? golfSlotsAvailable - 1 : golfSlotsAvailable + 1);
    }
  };

  const handleNumDelegateChange = (e) => {
    const { value } = e.target;
    setNumDelegates(value);
    setAssignedBooth(0);
    setFieldValue("numDelegates", value);
    setFieldValue(
      "delegates",
      Array.from({ length: value }, () => ({
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        shirtType: "",
        shirtSize: "",
        golfDay: false,
        assignToBooth: false,
        oneToOneMeetingTogether: false,
      }))
    );
    if (hasBooth) {
      assignDefaultDelegatesToBooth(value >= 2 ? 2 : 1);
    }
  };

  const handleNumSpouseChange = (e) => {
    const { value } = e.target;
    setFieldValue("numSpouses", value);
    setFieldValue(
      "spouses",
      Array.from({ length: value }, () => ({
        firstName: "",
        lastName: "",
        email: "",
        golfDay: false,
      }))
    );
  };

  const onSubmit = async (e) => {
    try {
      handleOpen();
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log(e.message);
    }
  };

  // const {
  //   getRootProps: getRootLogoProps,
  //   getInputProps: getInputLogoProps,
  //   acceptedFiles,
  // } = useDropzone({
  //   accept: {
  //     "image/jpeg": [],
  //     "image/jpg": [],
  //     "image/png": [],
  //   },
  //   onDrop: (acceptedFile) => {
  //     setImageUrl(URL.createObjectURL(acceptedFile[0]));
  //     setFieldValue("file", acceptedFile[0]);
  //     setAvatar(acceptedFile[0]);
  //   },
  // });
  // const fileAcceptedItems = acceptedFiles.map(() => (
  //   <MDBox
  //     mt={2}
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //     sx={{ width: { xs: "50%", sm: "30%", md: "15%" } }}
  //   >
  //     <img src={imageUrl} alt={imageUrl} width="100%" />
  //   </MDBox>
  // ));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      companyName: "",
      country: "",
      sponsorship: [],
      booth: "false",
      numDelegates: 2,
      numSpouses: "0",
      delegates: [],
      spouses: [],
    },

    validationSchema: conferenceCompanySchema,
    onSubmit,
  });

  const renderDelegateForms = values.delegates.map((_, idx) => (
    <Grid container spacing={1} justifyContent="center" alignItems="center" px={2.5} mt={2}>
      <Grid container columnSpacing={1}>
        <Grid xs={12} md={12} xl={12}>
          <MDBox my={0}>
            <MDTypography
              variant="button"
              color="black"
              fontWeight="bold"
              ml={1}
              textTransform="capitalize"
            >
              Delegate #{idx + 1}
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="text"
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.firstName &&
                touched?.delegates[idx]?.firstName &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.firstName &&
                  touched.delegates[idx]?.firstName &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.firstName && true)
              }
              label={
                errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.firstName &&
                touched.delegates[idx]?.firstName
                  ? errors.delegates[idx]?.firstName
                  : "First Name"
              }
              id={`delegates[${idx}].firstName`}
              onChange={handleChange}
              value={values?.delegates && values?.delegates[idx]?.firstName}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="text"
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.lastName &&
                touched?.delegates[idx]?.lastName &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.lastName &&
                  touched.delegates[idx]?.lastName &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.lastName && true)
              }
              label={
                errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.lastName &&
                touched.delegates[idx]?.lastName
                  ? errors.delegates[idx]?.lastName
                  : "Last Name"
              }
              id={`delegates[${idx}].lastName`}
              onChange={handleChange}
              value={values?.delegates && values?.delegates[idx]?.lastName}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="text"
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.title &&
                touched?.delegates[idx]?.title &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.title &&
                  touched.delegates[idx]?.title &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.title && true)
              }
              label={
                errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.title &&
                touched.delegates[idx]?.title
                  ? errors.delegates[idx]?.title
                  : "Job Title"
              }
              id={`delegates[${idx}].title`}
              onChange={handleChange}
              value={values?.delegates && values?.delegates[idx]?.title}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="email"
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.email &&
                touched?.delegates[idx]?.email &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.email &&
                  touched.delegates[idx]?.email &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.email && true)
              }
              label={
                errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.email &&
                touched.delegates[idx]?.email
                  ? errors.delegates[idx]?.email
                  : "Email"
              }
              id={`delegates[${idx}].email`}
              onChange={handleChange}
              value={values?.delegates && values?.delegates[idx]?.email}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox display="flex" alignItems="center" mt={2} px={0.5}>
            <FormControl
              container
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.shirtType &&
                touched?.delegates[idx]?.shirtType &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.shirtType &&
                  touched.delegates[idx]?.shirtType &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.shirtType && true)
              }
            >
              <InputLabel id="demo-simple-select-label">
                {errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.shirtType &&
                touched.delegates[idx]?.shirtType
                  ? errors.delegates[idx]?.shirtType
                  : "Shirt Type"}
              </InputLabel>
              <Select
                error={
                  errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.shirtType &&
                  touched?.delegates[idx]?.shirtType &&
                  true
                }
                focused={
                  (errors?.delegates &&
                    touched?.delegates &&
                    errors?.delegates[idx]?.shirtType &&
                    touched.delegates[idx]?.shirtType &&
                    true) ||
                  (values?.delegates && values.delegates[idx]?.shirtType && true)
                }
                label={
                  errors?.delegates &&
                  touched?.delegates &&
                  errors.delegates[idx]?.shirtType &&
                  touched.delegates[idx]?.shirtType
                    ? errors.delegates[idx]?.shirtType
                    : "Shirt Type"
                }
                id={`delegates[${idx}].shirtType`}
                fullWidth
                {...getFieldProps(`delegates[${idx}].shirtType`)}
                value={values?.delegates && values?.delegates[idx]?.shirtType}
                labelId="demo-simple-select-label"
                onChange={handleChange}
                sx={{ height: 39 }}
              >
                {GENERAL_CONSTS.CONF_SHIRT_TYPE.map((shirtType, idx) => (
                  <MenuItem key={idx} value={shirtType}>
                    {shirtType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox display="flex" alignItems="center" mt={2} px={0.5}>
            <FormControl
              container
              fullWidth
              error={
                errors?.delegates &&
                touched?.delegates &&
                errors?.delegates[idx]?.shirtSize &&
                touched?.delegates[idx]?.shirtSize &&
                true
              }
              focused={
                (errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.shirtSize &&
                  touched.delegates[idx]?.shirtSize &&
                  true) ||
                (values?.delegates && values.delegates[idx]?.shirtSize && true)
              }
            >
              <InputLabel id="demo-simple-select-label">
                {errors?.delegates &&
                touched?.delegates &&
                errors.delegates[idx]?.shirtSize &&
                touched.delegates[idx]?.shirtSize
                  ? errors.delegates[idx]?.shirtSize
                  : "Shirt Size"}
              </InputLabel>
              <Select
                error={
                  errors?.delegates &&
                  touched?.delegates &&
                  errors?.delegates[idx]?.shirtSize &&
                  touched?.delegates[idx]?.shirtSize &&
                  true
                }
                focused={
                  (errors?.delegates &&
                    touched?.delegates &&
                    errors?.delegates[idx]?.shirtSize &&
                    touched.delegates[idx]?.shirtSize &&
                    true) ||
                  (values?.delegates && values.delegates[idx]?.shirtSize && true)
                }
                label={
                  errors?.delegates &&
                  touched?.delegates &&
                  errors.delegates[idx]?.shirtSize &&
                  touched.delegates[idx]?.shirtSize
                    ? errors.delegates[idx]?.shirtSize
                    : "Shirt Size"
                }
                id={`delegates[${idx}].shirtSize`}
                fullWidth
                {...getFieldProps(`delegates[${idx}].shirtSize`)}
                value={values?.delegates && values?.delegates[idx]?.shirtSize}
                labelId="demo-simple-select-label"
                onChange={handleChange}
                sx={{ height: 39 }}
              >
                {GENERAL_CONSTS.CONF_SHIRT_SIZE.map((shirtSize, idx) => (
                  <MenuItem key={idx} value={shirtSize}>
                    {shirtSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={1} px={0.5}>
            <ThemeProvider theme={theme}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        golfSlotsAvailable <= 0 &&
                        values?.delegates &&
                        !values?.delegates[idx]?.golfDay
                      }
                      name={`delegates[${idx}].golfDay`}
                      value={values?.delegates && values?.delegates[idx]?.golfDay}
                      onChange={(e) =>
                        handleCheckChange(
                          `delegates[${idx}].golfDay`,
                          e.target.checked,
                          false,
                          true
                        )
                      }
                      onBlur={handleBlur}
                      checked={values?.delegates && values?.delegates[idx]?.golfDay}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="black"
                      width="max-content"
                    >
                      <span style={{ color: "black", fontSize: "11.2px" }}>
                        Golf Day ({GENERAL_CONSTS.CONF_PRICING["golf_day"]} USD per person)
                      </span>
                    </MDTypography>
                  }
                />
              </FormGroup>
            </ThemeProvider>
            {errors?.delegates &&
            touched?.delegates &&
            errors.delegates[idx]?.golfDay &&
            touched.delegates[idx]?.golfDay ? (
              <MDTypography variant="button" fontWeight="regular" fontSize={12} color="error">
                {errors?.delegates && errors.delegates[idx]?.golfDay}
              </MDTypography>
            ) : null}
          </MDBox>
        </Grid>

        {hasBooth ? (
          <Grid xs={12} sm={12} md={6} xl={6}>
            <MDBox mt={1} px={0.5}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          assignedBooth >= 2 &&
                          values?.delegates &&
                          !values?.delegates[idx]?.assignToBooth
                        }
                        name={`delegates[${idx}].assignToBooth`}
                        value={values?.delegates && values?.delegates[idx]?.assignToBooth}
                        onChange={(e) =>
                          handleCheckChange(
                            `delegates[${idx}].assignToBooth`,
                            e.target.checked,
                            true,
                            false
                          )
                        }
                        onBlur={handleBlur}
                        checked={values?.delegates && values?.delegates[idx]?.assignToBooth}
                      />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="black"
                        width="max-content"
                      >
                        <span style={{ color: "black", fontSize: "11.2px" }}>
                          Assign delegate #{idx + 1} to booth (Max 2 delegates)
                        </span>
                      </MDTypography>
                    }
                  />
                </FormGroup>
              </ThemeProvider>
              {errors?.delegates &&
              touched?.delegates &&
              errors.delegates[idx]?.assignToBooth &&
              touched.delegates[idx]?.assignToBooth ? (
                <MDTypography variant="button" fontWeight="regular" fontSize={12} color="error">
                  {errors?.delegates && errors.delegates[idx]?.assignToBooth}
                </MDTypography>
              ) : null}
            </MDBox>
          </Grid>
        ) : null}

        <Grid item xs={12} md={12} xl={12}>
          <MDBox>
            <ThemeProvider theme={theme}>
              <FormControl>
                <MDTypography>
                  <span
                    style={{
                      textTransform: "capitalize",
                      color: "black",
                      fontSize: "0.65rem",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    1:1 Meetings Together
                  </span>
                  <br />
                  <span
                    style={{
                      color: "black",
                      fontSize: "0.65rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontStyle: "italic",
                    }}
                  >
                    If you are brining multiple delegates would you like to have your meeting
                    seperate or together?
                  </span>
                </MDTypography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name={`delegates[${idx}].oneToOneMeetingTogether`}
                  value={values?.delegates && values?.delegates[idx]?.oneToOneMeetingTogether}
                  onChange={handleChange}
                >
                  <MDBox display="flex">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          <span
                            style={{
                              color: "black",
                              fontSize: "0.65rem",
                              fontFamily: "Poppins",
                              fontWeight: "400",
                            }}
                          >
                            Together
                          </span>
                        </MDTypography>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          <span
                            style={{
                              color: "black",
                              fontSize: "0.65rem",
                              fontFamily: "Poppins",
                              fontWeight: "400",
                            }}
                          >
                            Separate
                          </span>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                </RadioGroup>
              </FormControl>
            </ThemeProvider>
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  ));

  const renderSpouseForms = values.spouses.map((_, idx) => (
    <Grid container spacing={1} justifyContent="center" alignItems="center" px={2.5} mt={2}>
      <Grid container columnSpacing={1}>
        <Grid xs={12} md={12} xl={12}>
          <MDBox my={0}>
            <MDTypography
              variant="button"
              color="black"
              fontWeight="bold"
              ml={1}
              textTransform="capitalize"
            >
              Spouse #{idx + 1}
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="text"
              fullWidth
              error={
                errors?.spouses &&
                touched?.spouses &&
                errors?.spouses[idx]?.firstName &&
                touched?.spouses[idx]?.firstName &&
                true
              }
              focused={
                (errors?.spouses &&
                  touched?.spouses &&
                  errors?.spouses[idx]?.firstName &&
                  touched.spouses[idx]?.firstName &&
                  true) ||
                (values?.spouses && values.spouses[idx]?.firstName && true)
              }
              label={
                errors?.spouses &&
                touched?.spouses &&
                errors.spouses[idx]?.firstName &&
                touched.spouses[idx]?.firstName
                  ? errors.spouses[idx]?.firstName
                  : "First Name"
              }
              id={`spouses[${idx}].firstName`}
              onChange={handleChange}
              value={values?.spouses && values?.spouses[idx]?.firstName}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={6} xl={6}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="text"
              fullWidth
              error={
                errors?.spouses &&
                touched?.spouses &&
                errors?.spouses[idx]?.lastName &&
                touched?.spouses[idx]?.lastName &&
                true
              }
              focused={
                (errors?.spouses &&
                  touched?.spouses &&
                  errors?.spouses[idx]?.lastName &&
                  touched.spouses[idx]?.lastName &&
                  true) ||
                (values?.spouses && values.spouses[idx]?.lastName && true)
              }
              label={
                errors?.spouses &&
                touched?.spouses &&
                errors.spouses[idx]?.lastName &&
                touched.spouses[idx]?.lastName
                  ? errors.spouses[idx]?.lastName
                  : "Last Name"
              }
              id={`spouses[${idx}].lastName`}
              onChange={handleChange}
              value={values?.spouses && values?.spouses[idx]?.lastName}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={12} xl={12}>
          <MDBox mt={2} px={0.5}>
            <MDInput
              type="email"
              fullWidth
              error={
                errors?.spouses &&
                touched?.spouses &&
                errors?.spouses[idx]?.email &&
                touched?.spouses[idx]?.email &&
                true
              }
              focused={
                (errors?.spouses &&
                  touched?.spouses &&
                  errors?.spouses[idx]?.email &&
                  touched.spouses[idx]?.email &&
                  true) ||
                (values?.spouses && values.spouses[idx]?.email && true)
              }
              label={
                errors?.spouses &&
                touched?.spouses &&
                errors.spouses[idx]?.email &&
                touched.spouses[idx]?.email
                  ? errors.spouses[idx]?.email
                  : "Email"
              }
              id={`spouses[${idx}].email`}
              onChange={handleChange}
              value={values?.spouses && values?.spouses[idx]?.email}
              onBlur={handleBlur}
            />
          </MDBox>
        </Grid>

        <Grid xs={12} sm={12} md={12} xl={12}>
          <MDBox mt={1} px={0.5}>
            <ThemeProvider theme={theme}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        golfSlotsAvailable <= 0 && values?.spouses && !values?.spouses[idx]?.golfDay
                      }
                      name={`spouses[${idx}].golfDay`}
                      value={values?.spouses && values?.spouses[idx]?.golfDay}
                      onChange={(e) =>
                        handleCheckChange(`spouses[${idx}].golfDay`, e.target.checked, false, true)
                      }
                      onBlur={handleBlur}
                      checked={values?.spouses && values?.spouses[idx]?.golfDay}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="black"
                      width="max-content"
                    >
                      <span style={{ color: "black", fontSize: "11.2px" }}>
                        Golf Day ({GENERAL_CONSTS.CONF_PRICING["golf_day"]} USD per person)
                      </span>
                    </MDTypography>
                  }
                />
              </FormGroup>
            </ThemeProvider>
            {errors?.spouses &&
            touched?.spouses &&
            errors.spouses[idx]?.golfDay &&
            touched.spouses[idx]?.golfDay ? (
              <MDTypography variant="button" fontWeight="regular" fontSize={12} color="error">
                {errors?.spouses && errors.spouses[idx]?.golfDay}
              </MDTypography>
            ) : null}
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <>
      <style>
        {` .ql-snow .ql-stroke , .ql-snow .ql-picker {
            stroke: #7b809a;
            color:#7b809a;
            font-family: 'poppins;
          }
          .ql-snow .ql-picker.ql-expanded .ql-picker-options {
            z-index: 9;
          }
          .ql-editor {
            color: black !important;
            min-height: 250px;
          }

          .css-103b10f-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
            height: 38px;
          }
          `}
      </style>

      <OnlineFormLayout image={bgImage}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={11} md={8} lg={8} xl={8} mt={6}>
            <Card sx={{ px: 1 }}>
              <MDBox
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
                mx={2}
                mt={-3}
                py={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="primaryDark" my={1}>
                  Conference Registration
                </MDTypography>
              </MDBox>
              {JSON.stringify(availability) === "{}" ? (
                <MDBox my={6} display="flex" justifyContent="center">
                  <CircularProgress size={40} color="primaryDark" />
                </MDBox>
              ) : (
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit}>
                    <MDBox mb={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                          {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
                          <Grid container spacing={1}>
                            <Grid xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="bold"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  Company Details
                                </MDTypography>
                              </MDBox>
                            </Grid>

                            <Grid xs={12} md={12} xl={12}>
                              <MDBox px={0.5} mt={1} ml={1}>
                                <MDInput
                                  type="text"
                                  fullWidth
                                  error={errors.companyName && touched.companyName && true}
                                  focused={
                                    (errors.companyName && touched.companyName && true) ||
                                    (values.companyName && true)
                                  }
                                  label={
                                    errors.companyName && touched.companyName
                                      ? errors.companyName
                                      : "Company Name"
                                  }
                                  id="companyName"
                                  onChange={handleChange}
                                  value={values.companyName}
                                  onBlur={handleBlur}
                                />
                              </MDBox>
                            </Grid>

                            <Grid xs={12} md={12} xl={12} mt={2} ml={1}>
                              <MDBox display="flex" alignItems="center" px={0.5}>
                                <FormControl
                                  container
                                  fullWidth
                                  error={errors.country && touched.country && true}
                                  focused={
                                    (errors.country && touched.country && true) ||
                                    (values.country && true)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    {errors.country && touched.country ? errors.country : "Country"}
                                  </InputLabel>
                                  <Select
                                    error={errors.country && touched.country && true}
                                    focused={
                                      (errors.country && touched.country && true) ||
                                      (values.country && true)
                                    }
                                    label={
                                      errors.country && touched.country ? errors.country : "Country"
                                    }
                                    id="country"
                                    fullWidth
                                    {...getFieldProps("country")}
                                    value={values.country}
                                    labelId="demo-simple-select-label"
                                    onChange={handleChange}
                                    sx={{ height: 39 }}
                                  >
                                    {GENERAL_CONSTS.COUNTRIES.map((country, idx) => (
                                      <MenuItem key={idx} value={country}>
                                        {country}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </MDBox>
                            </Grid>

                            {availability["booth"] > 0 ? (
                              <Grid item xs={12} md={12} xl={12} mt={1} mb={0}>
                                <MDBox>
                                  <ThemeProvider theme={theme}>
                                    <FormControl>
                                      <MDTypography>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                            color: "black",
                                            fontSize: "0.65rem",
                                            fontFamily: "Poppins",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Booth ({GENERAL_CONSTS.CONF_PRICING["booth"]} USD)
                                        </span>
                                      </MDTypography>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={"false"}
                                        name="booth"
                                        value={values.booth}
                                        onChange={(e) =>
                                          handleCheckChange("booth", e.target.value, false, false)
                                        }
                                      >
                                        <MDBox ml={1} display="flex">
                                          <FormControlLabel
                                            value={"true"}
                                            control={<Radio />}
                                            label={
                                              <MDTypography
                                                variant="button"
                                                color="text"
                                                fontWeight="regular"
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    fontSize: "0.65rem",
                                                    fontFamily: "Poppins",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  Yes
                                                </span>
                                              </MDTypography>
                                            }
                                          />
                                          <FormControlLabel
                                            value={"false"}
                                            control={<Radio />}
                                            label={
                                              <MDTypography
                                                variant="button"
                                                color="text"
                                                fontWeight="regular"
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    fontSize: "0.65rem",
                                                    fontFamily: "Poppins",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No
                                                </span>
                                              </MDTypography>
                                            }
                                          />
                                        </MDBox>
                                      </RadioGroup>
                                    </FormControl>
                                  </ThemeProvider>
                                </MDBox>
                              </Grid>
                            ) : null}

                            <Grid xs={12} md={12} xl={12} mt={1.5}>
                              <MDBox>
                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="bold"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  Sponsorship
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid container ml={1} mb={1}>
                              {GENERAL_CONSTS.CONF_SPONSORSHIP_TYPE.map((sponsorship, idx) => (
                                <Grid xs={12} sm={12} md={6} xl={6}>
                                  <MDBox mt={1} px={0.5}>
                                    <ThemeProvider theme={theme}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={
                                                availability[sponsorship.key] > 0 ? false : true
                                              }
                                              value={values.sponsorship}
                                              onChange={(e) =>
                                                handleSponsorshipChange(
                                                  sponsorship.name,
                                                  sponsorship.key
                                                )
                                              }
                                              onBlur={handleBlur}
                                              checked={values.sponsorship.includes(
                                                sponsorship.name
                                              )}
                                            />
                                          }
                                          label={
                                            <MDTypography
                                              variant="button"
                                              fontWeight="regular"
                                              color="black"
                                              width="max-content"
                                              sx={{
                                                color:
                                                  availability[sponsorship.key] > 0
                                                    ? "black"
                                                    : "grey",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {sponsorship.name}
                                              </span>
                                              <br />
                                              <span style={{ fontSize: "12px" }}>
                                                ({sponsorship.price} USD)
                                              </span>
                                            </MDTypography>
                                          }
                                        />
                                      </FormGroup>
                                    </ThemeProvider>
                                  </MDBox>
                                </Grid>
                              ))}
                            </Grid>

                            <Grid xs={12} md={12} xl={12} mt={1.5} ml={1}>
                              <MDBox display="flex" flexDirection="column">
                                <MDTypography
                                  mb={1}
                                  variant="button"
                                  color="black"
                                  textAlign="center"
                                >
                                  Click below for more information on what our sponsorship packages
                                  entail, and the brand recognition you will receive.
                                </MDTypography>
                                <MDBox display="flex" justifyContent="center">
                                  <MDButton
                                    href={GENERAL_CONSTS.CONF_BROCHURE}
                                    target="_blank"
                                    size="small"
                                    color="primary"
                                  >
                                    Conference Brochure
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12} xl={12} mt={2} mb={0}>
                              <MDBox display="flex" alignItems="center" px={0.5}>
                                <FormControl
                                  container
                                  fullWidth
                                  error={errors.numDelegates && touched.numDelegates && true}
                                  focused={
                                    (errors.numDelegates && touched.numDelegates && true) ||
                                    (values.numDelegates && true)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    {errors.numDelegates && touched.numDelegates
                                      ? errors.numDelegates
                                      : "Number of Delegates"}
                                  </InputLabel>
                                  <Select
                                    error={errors.numDelegates && touched.numDelegates && true}
                                    focused={
                                      (errors.numDelegates && touched.numDelegates && true) ||
                                      (values.numDelegates && true)
                                    }
                                    label={
                                      errors.numDelegates && touched.numDelegates
                                        ? errors.numDelegates
                                        : "Number of Delegates"
                                    }
                                    id="numDelegates"
                                    fullWidth
                                    {...getFieldProps("numDelegates")}
                                    value={values.numDelegates}
                                    labelId="demo-simple-select-label"
                                    onChange={handleNumDelegateChange}
                                    sx={{ height: 39 }}
                                  >
                                    {Array.from(
                                      {
                                        length:
                                          availability["delegate_pass"] < 10
                                            ? availability["delegate_pass"]
                                            : 10,
                                      },
                                      (_, i) => i + 1
                                    ).map((num, idx) => (
                                      <MenuItem key={idx} value={num}>
                                        {num}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12} xl={12} mt={1} mb={0}>
                              <MDBox display="flex" alignItems="center" px={0.5}>
                                <FormControl
                                  container
                                  fullWidth
                                  error={errors.numSpouses && touched.numSpouses && true}
                                  focused={
                                    (errors.numSpouses && touched.numSpouses && true) ||
                                    (values.numSpouses && true)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    {errors.numSpouses && touched.numSpouses
                                      ? errors.numSpouses
                                      : "Number of Spouses"}
                                  </InputLabel>
                                  <Select
                                    error={errors.numSpouses && touched.numSpouses && true}
                                    focused={
                                      (errors.numSpouses && touched.numSpouses && true) ||
                                      (values.numSpouses && true)
                                    }
                                    label={
                                      errors.numSpouses && touched.numSpouses
                                        ? errors.numSpouses
                                        : "Number of Spouses"
                                    }
                                    id="numSpouses"
                                    fullWidth
                                    {...getFieldProps("numSpouses")}
                                    value={values.numSpouses}
                                    labelId="demo-simple-select-label"
                                    onChange={handleNumSpouseChange}
                                    sx={{
                                      height: 39,
                                    }}
                                  >
                                    {Array.from(
                                      {
                                        length:
                                          availability["spouse_pass"] < 10
                                            ? availability["spouse_pass"] + 1
                                            : 11,
                                      },
                                      (_, i) => i
                                    ).map((num, idx) => (
                                      <MenuItem key={idx} value={num === 0 ? "0" : num}>
                                        {num === 0 ? "0" : num}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </MDBox>
                            </Grid>

                            {numDelegates > 0 ? (
                              <Grid
                                container
                                spacing={1}
                                diplay="flex"
                                justifyContent="center"
                                px={1}
                                mt={1}
                              >
                                <Grid xs={12} md={12} xl={12}>
                                  <MDBox my={1}>
                                    <MDTypography
                                      variant="button"
                                      color="black"
                                      fontWeight="bold"
                                      ml={1}
                                      textTransform="capitalize"
                                    >
                                      Shirt Sizing Chart
                                    </MDTypography>
                                  </MDBox>
                                </Grid>

                                <MDBox mt={2} px={6}>
                                  <img
                                    alt=""
                                    width="100%"
                                    height="auto"
                                    src={shirtSizingChart}
                                    style={{ borderRadius: "17px", objectFit: "cover" }}
                                  />
                                </MDBox>
                              </Grid>
                            ) : null}

                            {renderDelegateForms}
                            {renderSpouseForms}
                          </Grid>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox mt={4} mb={1} display="flex" justifyContent="end">
                      <MDBox mr={2}>
                        <MDButton
                          variant="outlined"
                          color="primary"
                          sx={{ px: 5 }}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </MDButton>
                      </MDBox>

                      <MDButton variant="gradient" color="primary" sx={{ px: 5 }} type="submit">
                        {saving ? <CircularProgress size={20} color="textButton" /> : "Register"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
        <MDBox sx={{ overflowY: "visible" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{ overflowY: "visible" }}
          >
            <MDBox mt={4} mx={5} mb={2}>
              <MDTypography display="block" variant="h6" color="black" textAlign="center">
                Register for Conference
              </MDTypography>
            </MDBox>

            <MDBox
              mb={1}
              sx={{ mx: { xs: 5, md: 10 } }}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <MDTypography display="block" variant="button" color="black" textAlign="center">
                Would you like to continue registering for the conference?
              </MDTypography>
              {saving && <CircularProgress sx={{ mt: 2 }} />}
            </MDBox>
            <MDBox mt={2} mb={4} display="flex" justifyContent="center">
              <MDBox mr={2}>
                <MDButton
                  onClick={handleClose}
                  autoFocus
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Cancel
                </MDButton>
              </MDBox>
              <MDButton
                autoFocus
                variant="gradient"
                color="primary"
                onClick={submitData}
                size="small"
              >
                Register
              </MDButton>
            </MDBox>
          </Dialog>
        </MDBox>

        <MDBox sx={{ overflowY: "visible" }}>
          <Dialog
            open={openTwo}
            aria-labelledby="responsive-dialog-title"
            sx={{ overflowY: "visible" }}
          >
            <Divider />
            <MDBox mb={1} sx={{ mx: { xs: 5, md: 10 } }}>
              <MDTypography display="block" variant="button" color="black" textAlign="center">
                Thank you for registering for Freyt Meet 2025! <br /> We'll be in touch with further
                details within 24 hours. We look forward to welcoming you!
              </MDTypography>
            </MDBox>
            <MDBox mt={2} mb={4} display="flex" justifyContent="center">
              <MDButton
                autoFocus
                variant="gradient"
                color="primary"
                size="small"
                sx={{ mx: 2, px: 5 }}
                onClick={() => clearLocal()}
              >
                OK
              </MDButton>
            </MDBox>
          </Dialog>
        </MDBox>

        <Snackbar
          open={openn}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
            Failed to register, please try again later.
          </Alert>
        </Snackbar>
      </OnlineFormLayout>
    </>
  );
}

export default ConferenceCompanyInfo;
